<template>
  <div class="contacts-wrapper">
    <a class="contacts-item" 
      :class="{disabled: !locationItem.phone}"
      :href="'tel:'+locationItem.phone" >
      <i class="contacts-icon noq-phone"/>
      <span class="contacts-text">{{ $t("components.ContactsBar.phone") }}</span>
    </a>
    <a class="contacts-item" 
       :class="{disabled: !locationItem.email}"
       :href="'mailto:'+locationItem.email" >
      <i class="contacts-icon noq-email"/>
      <span class="contacts-text">{{ $t("components.ContactsBar.email") }}</span>
    </a>
    <a class="contacts-item"
      :class="{disabled: !locationItem.website}"
      :href="locationItem.website" target="_blank"
    >
      <i class="contacts-icon noq-website"/>
      <span class="contacts-text">{{ $t("components.ContactsBar.website") }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    locationItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.contacts-wrapper {
  display: grid;
  height: 36px;
  grid-auto-flow: column;

  .contacts-item {
    padding: 5px 5px;
    vertical-align: middle;
    color: $color_primary;
    text-align: center;

    .contacts-text {
      margin-left: 8px;
      font-size: 12.25px;
    }

    &:hover{
      background-color: $color_highlight;
      cursor: pointer;
    }
  }
  .disabled {
    .contacts-icon {
      background-color: $color_forms-border;
    }

    .contacts-text {
      color: $color_forms-border;
    }

    &:hover{
      background-color: unset;
      cursor: pointer;
    }
  }
}

</style>