<template>
  <div id="app">
    <!-- <language-selector />
    <router-link :to="{ name: 'home' }">
      Home
    </router-link>

    <router-link :to="{ name: 'pharmaMap' }">
      Map
    </router-link> -->
    <router-view/>
  </div>
</template>

<script>
// import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  components: {
    // LanguageSelector
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>