import { render, staticRenderFns } from "./TestVacLanding.vue?vue&type=template&id=db72a3dc&scoped=true&"
import script from "./TestVacLanding.vue?vue&type=script&lang=js&"
export * from "./TestVacLanding.vue?vue&type=script&lang=js&"
import style0 from "./TestVacLanding.vue?vue&type=style&index=0&id=db72a3dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db72a3dc",
  null
  
)

export default component.exports