<template>
  <div class="app-container">
    <div class="locator-container">
      <div class="header-content-container">
        <div class="header">
          <div class="header-left">{{ $t('views.Home.headerText') }}</div>
          <div id="language-dropdown-toggle" class="header-right">
            <language-selector></language-selector>
          </div>
        </div>
        <div class="content-container">
          <div class="intro-text">{{ $t('views.VaccinationList.intro') }}</div>
          <div class="cta-container">
            <div class="cta-title">{{ $t('views.VaccinationList.ctaTitle') }}</div>
            <div class="cta-card" v-for="(location, index) in $t('views.VaccinationList.cardContent')" :key="index">
              <div>
                <div class="card-location">{{ location.name }}</div>
                <div class="card-date">{{ location.date }}</div>
                <div class="card-time">{{ location.time }}</div>
              </div>
              <a :href="location.link" target="_blank" class="card-cta">{{ $t('views.VaccinationList.ctaToBookPage') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="sponsor-container">
        <div>{{ $t('views.TestVacLanding.support') }}</div>
        <img class="sponsor-image" src="/st_vac_sponsor.jpg" alt="">
      </div>
      <div class="footer-container">
        <div>
          <a href="https://www.no-q.info/" target="_blank">{{ $t('views.Home.serviceProvidedByNoQ') }}</a> •
          <a href="https://www.no-q.info/impressum/" target="_blank">{{ $t('views.Home.imprint') }}</a> •
          <a href="https://www.no-q.info/datenschutz/" target="_blank">{{ $t('views.Home.conditions') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  components: {
    LanguageSelector
  },
}
</script>

<style scoped lang="scss">
  .app-container {
    margin-left: 18px;
    margin-right: 18px;
  }
  .locator-container {
    font-family: "Fira Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #102A43;
    text-align: left;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-content-container {
    min-height: calc(100vh - 60px);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    color: #102A43;
    font-size: 14px;
  }
  .header-left {
    font-weight: bold;
  }
  .header-right {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .content-container {
    margin-top: 80px;
  }
  .intro-text {
    font-size: 17.5px;
    color: #778088;
  }
  .cta-container {
    margin-top: 50px;
  }
  .cta-title {
    font-size: 24.5px;
    color: #102A43;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .cta-card {
    border: solid 1px #47647e;
    border-radius: 0.2rem;
    padding: 16px;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .card-location {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .card-cta {
    @media (min-width: 768px) {
      margin-top: 0px;
    }
    margin-top: 10px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #334F68;
    border-radius: 4px;
    font-size: 18px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    text-decoration: none;
  }
  .card-cta:hover {
    text-decoration: underline;
  }
  .sponsor-container {
    margin-top: 70px;
    font-weight: bold;
    font-size: 17.5px;
  }
  .sponsor-image {
    width: 100%;
    object-fit: contain;
  }
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .footer-container a {
    text-decoration: none;
    color: inherit
  }


  /* responsive */
  @media (min-width: 768px) {
    .locator-container {
      max-width: 700px;
    }
  }
</style>