<template>
  <map-locator />
</template>

<script>
import MapLocator from '@/components/MapLocator.vue'

export default {
  name: 'PharmaMap',
  components: {
    MapLocator
  }
}
</script>
