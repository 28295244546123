<template>
  <div v-if="showFilters">
    <div>
      <v-date-picker v-model="selectedTimespan" is-range is-expanded 
        :columns="$screens({ md: 2 }, 1)" 
        :rows="$screens({ sm: 1 }, 2)" 
        :popover="{placement: 'bottom'}"
        :min-date="new Date()"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="range-selector-form-container">
            <div class="daterange-picker-collapsed" >
              <span v-on="inputEvents.start">{{ inputValue.start }} - {{ inputValue.end }}</span>
            </div>
          </div>
        </template>
      </v-date-picker>
    </div>

    <div class="select-wrapper">
      <select v-if="showPersonFilter" @change="changePersonFilter">
        <option
          v-for="person in personValues"
          :key="person.value"
          :value="person.value"
        >
          {{ person.value }} {{ personValuesLabel }}
        </option>
      </select>
    </div>
    <div
      v-if="ageValues.length > 0"
      class="dropdown-check-list"
      :class="{ visible: agesVisible }"
      v-click-outside="hideAgeSelect"
    >
      <span class="anchor" @click="agesVisible = !agesVisible">
        {{ $t("components.MapFilters.age_filter") }}
      </span>
      <div class="items-container">
        <ul class="items">
          <li v-for="(i, idx) in ageValues" :key="idx">
            <span>
              <input type="checkbox" :value="i" v-model="selectedAges" />
              {{ i.label }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="serviceValues.length > 0"
      class="dropdown-check-list"
      :class="{ visible: servicesVisible }"
      v-click-outside="hideServiceSelect"
    >
      <span class="anchor" @click="servicesVisible = !servicesVisible">
        {{ $t("components.MapFilters.service_filter") }}
      </span>
      <div class="items-container">
        <ul class="items">
          <li v-for="(i, idx) in serviceValues" :key="idx">
            <span>
              <input
                type="radio"
                name="service_checkbox"
                :value="i"
                v-model="selectedService"
              />
              {{ i.label }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="vaccineValues.length > 0 && showVaccineFilter"
      class="dropdown-check-list"
      :class="{ visible: vaccinesVisible }"
      v-click-outside="hideVaccineSelect"
    >
      <span class="anchor" @click="vaccinesVisible = !vaccinesVisible">
        {{ $t("components.MapFilters.vaccine_filter") }}
      </span>
      <div class="items-container">
        <ul class="items">
          <li v-for="(i, idx) in vaccineValues" :key="idx">
            <span>
              <input type="checkbox" :value="i" v-model="selectedVaccines" />
              {{ i.label }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="allIntegrations.length > 0"
      class="dropdown-check-list"
      :class="{ visible: appsVisible }"
      v-click-outside="hideAppSelect"
    >
      <span class="anchor" @click="appsVisible = !appsVisible">
        {{ $t("components.MapFilters.connectedApps") }}
      </span>
      <div class="items-container">
        <ul class="items">
          <li
            v-for="(i, idx) in allIntegrations"
            :key="idx"
            @click="toggleAppSelection(i)"
          >
            <span>
              <input
                type="checkbox"
                :value="i"
                v-model="selectedIntegrations"
              />
              {{ $t(`components.AppIntegrations.${i}`) }}
            </span>
            <div
              class="app-integration-icon"
              :style="{
                'background-image': `url(${getIntegrationIcon(i)}`,
              }"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { EventBus } from "@/plugins/event-bus.js";
import moment from "moment";

const DAYS_IN_ADVANCE = 14;

export default {
  props: {
    showPersonFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      personValues: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
      ],
      selectedTimespan: null,
      dateValues: [],
      appsVisible: false,
      vaccinesVisible: false,
      servicesVisible: false,
      showVaccineFilter: true,
      agesVisible: false,
      selectedDateTime: {},
      selectedIntegrations: [],
      selectedVaccines: [],
      selectedAges: [],
      selectedService: null,
    };
  },
  computed: {
    showFilters() {
      return process.env.VUE_APP_HIDE_FILTERS === "false";
    },
    allIntegrations() {
      let ai = [];
      let integrationString = process.env.VUE_APP_INTEGRATIONS;

      if (integrationString && integrationString.length > 0) {
        ai = process.env.VUE_APP_INTEGRATIONS.split(",");
      }

      return ai;
    },
    personValuesLabel() {
      return this.$t("components.MapFilters.person");
    },
    ageValues() {
      const ageValues = [];
      ageValues.push({
        label: this.$t("components.MapFilters.children"),
        value: "children",
      });
      ageValues.push({
        label: this.$t("components.MapFilters.teens"),
        value: "teens",
      });
      ageValues.push({
        label: this.$t("components.MapFilters.adults"),
        value: "adults",
      });
      ageValues.push({
        label: this.$t("components.MapFilters.seniors"),
        value: "seniors",
      });

      return ageValues;
    },
    vaccineValues() {
      const vaccineValues = [];
      vaccineValues.push({label: "AstraZeneca (Vaxzevria)", value: "astrazeneca"});
      vaccineValues.push({ label: "Biontech (Comirnaty)", value: "biontech" });
      vaccineValues.push({ label: "Moderna (Spikevax)", value: "moderna" });
      vaccineValues.push({ label: "Johnson&Johnson (Vaccine Janssen)", value: "johnson"});
      vaccineValues.push({ label: "Novavax (Nuvaxovid)", value: "novavax" });
      return vaccineValues;
    },
    serviceValues() {
      const serviceValues = [];
      serviceValues.push({
        label: this.$t("components.MapFilters.any"),
        value: "",
      });
      serviceValues.push({
        label: this.$t("components.MapFilters.fluvax"),
        value: "vax-flu",
      });
      serviceValues.push({
        label: this.$t("components.MapFilters.covidvax"),
        value: "vax-covid",
      });

      return serviceValues;
    },
  },
  beforeMount() {
    this.selectedTimespan = this.defaultTimespan();
  },
  watch: {
    selectedTimespan: {
      deep: true,
      handler(valToEmit) {
        const now = DateTime.now()
        const startToday = DateTime.fromJSDate(valToEmit.start).hasSame(now, "day")
        const start = startToday ? DateTime.fromJSDate(valToEmit.start) : DateTime.fromJSDate(valToEmit.start).startOf('day')
        const vte = {
          start: start.toJSDate(),
          end: DateTime.fromJSDate(valToEmit.end).endOf('day').toJSDate()
        }
        let filter = this.selectedDateTime;
        filter["date_range"] = vte
        EventBus.$emit('date-time-filter-changed', filter)
      }
    },
    selectedIntegrations(valToEmit) {
      let filter = this.selectedDateTime;
      filter["integrations"] = valToEmit;
      EventBus.$emit("date-time-filter-changed", filter);
    },
    selectedAges(valToEmit) {
      let filter = this.selectedDateTime;
      filter["age_groups"] = valToEmit.map((e) => e.value);
      EventBus.$emit("date-time-filter-changed", filter);
    },
    selectedVaccines(valToEmit) {
      let filter = this.selectedDateTime;
      filter["vaccines"] = valToEmit.map((e) => e.value);
      EventBus.$emit("date-time-filter-changed", filter);
    },

    selectedService(valToEmit) {
      if (valToEmit.value == "vax-flu") {
        this.selectedVaccines = [];
        this.showVaccineFilter = false;
      } else {
        this.showVaccineFilter = true;
      }
      let filter = this.selectedDateTime;
      filter["service"] = valToEmit.value;
      EventBus.$emit("date-time-filter-changed", filter);
    },
  },
  methods: {
    defaultTimespan() {
      const start = new Date();
      const end = DateTime.fromJSDate(start).endOf('day').plus({months: 1}).toJSDate()
      return {
        start: start, 
        end: end
      }
    },
    toggleAppSelection(app) {
      let idx = this.selectedIntegrations.indexOf(app);
      if (idx >= 0) {
        this.selectedIntegrations.splice(idx, 1);
      } else {
        this.selectedIntegrations.push(app);
      }
    },
    generateDays() {
      for (let i = 0; i < DAYS_IN_ADVANCE; i++) {
        let date = new DateTime.local().plus({ days: i });
        this.dateValues.push({
          value: { year: date.year, month: date.month, day: date.day },
          label: moment(date.toISODate()).format("DD-MM-yyy"),
        });
      }
    },
    timeDisabled(time) {
      // timeRange can be 00:00 - 01:00
      if (time === null) return false;
      let now = new DateTime.local();
      if (now.day == this.selectedDateTime.date.day) {
        return time < now.hour;
      } else {
        return false;
      }
    },
    changePersonFilter(e) {
      EventBus.$emit("person-filter-changed", e.target.value);
    },
    getIntegrationIcon(key) {
      if (key == "cwa") {
        return require("@/assets/icons/cwa_app_icon.png");
      } else if (key == "luca") {
        return require("@/assets/icons/luca_app_icon.png");
      } else if (key == "dcc") {
        return require("@/assets/icons/dcc_icon.png");
      } else {
        return null;
      }
    },

    hideVaccineSelect() {
      this.vaccinesVisible = false;
    },
    hideAgeSelect() {
      this.agesVisible = false;
    },
    hideServiceSelect() {
      this.servicesVisible = false;
    },
    hideAppSelect() {
      this.appsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 18px 4px 4px;
  background-color: $color_white;
  margin-right: $spacing-xxs;
  outline: none;
  font-family: Arial;
  font-size: 12px;
}

.select-wrapper {
  position: relative;
  display: inline;
}

.select-wrapper:after {
  position: absolute;
  content: "";
  border-left: 3px solid black;
  border-top: 3px solid black;
  padding: 4px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.7);
}

.dropdown-check-list {
  display: inline-block;
  position: relative;
  margin-top: 6px;
  margin-right: 6px;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 50px 4px 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: white;
  font-family: Arial;
  font-size: 12px;
}

.dropdown-check-list.visible .anchor {
  border-radius: 4px 4px 0 0 !important;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 3px solid black;
  border-top: 3px solid black;
  padding: 4px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.7);
}

.dropdown-check-list .items-container {
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 0 0 4px 4px;
}
.dropdown-check-list ul.items {
  padding: 0;
  display: none;
  margin: 0;
  width: fit-content;
  border: 1px solid #dee2e6;
  background-color: white;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.dropdown-check-list ul.items li {
  padding-top: 6px;
  padding-bottom: 6px;
  list-style: none;
  font-size: 12px;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding-right: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.dropdown-check-list.visible .items {
  display: block;
}

.app-integration-icon {
  margin-top: 3px;
  margin-right: $spacing-4xs;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.daterange-picker-collapsed {
  font-size: 12px;
  border: 1px solid #dee2e6;
  border-radius: 0 0 4px 4px;
  background-color: white;
  padding: 4px;
  display: inline-block;
}
</style>
