<template>
  <div class="app-container">
    <div v-if="formSubmitted" class="thank-you-page">
      <div class="ty-card">
        <img class="check" :src="require('@/assets/images/ty-check.png')">
        <h2>Danke</h2>
        <p style="text-align: center; line-height: 1.5">Vielen Dank für Ihre Meldung. Die Impfstelle wird auf <a href="https://impftermine.de">impftermine.de</a> veröffentlicht, sobald wir Ihre Information überprüft haben.</p>
        <p style="margin-top: 60px;"><a class="ty-button" href="#/">Zurück</a></p>
      </div>
    </div>
    <div v-else class="locator-container">
      <div class="header-content-container">
        <div class="header">
          <div class="header-left">{{ $t('views.NewLocation.headerText') }}</div>
        </div>
        <div class="content-container">
          <p>Auf Impftermine.de sollen Bürgerinnen und Bürgern wohnortnahe Impfstellen schnell und unkompliziert angezeigt werden. Nutzen Sie dieses Formular um uns eine noch nicht eingetragene Impfstelle zu melden.
            (Sollten Sie als Landkreis, Kommune oder Verband mehrere Impfstellen melden wollen kontaktieren Sie uns unter <a href="mailto:support.impftermine@no-q.info">support.impftermine@no-q.info</a>).
          </p>
          <p>
            Die Datenschutzinformationen für die Meldung finden Sie <a href="/downloads/Datenschutzinfo_Registrierung_Impftermine.pdf" target="_blank">hier</a>.
          </p>
        </div>
        <form class="nl-form" id="newLocation" @submit="submitForm" action="#" method="post">
          <div>
            <label for="name">Name der Impfstelle*</label>
            <input @keyup="clearError('name')" :class="{'invalid-field': findInputError('name') }" v-model="name">
            <sub style="color: red;" v-if="findInputError('name')">{{findInputError('name').message}}</sub>
          </div>
          <div>
            <label for="address">Straße und Hausnummer *</label>
            <input @keyup="clearError('address')" :class="{'invalid-field': findInputError('address') }" v-model="address">
            <sub style="color: red;" v-if="findInputError('address')">{{findInputError('address').message}}</sub>
          </div>
          <div class="inline-inputs">
            <div>
              <label for="zip">Postleitzahl *</label>
              <input @keyup="clearError('zip')" :class="{'invalid-field': findInputError('zip') }" v-model="zip">
              <sub style="color: red;" v-if="findInputError('zip')">{{findInputError('zip').message}}</sub>
            </div>
            <div class="spacer"></div>
            <div>
              <label for="city">Stadt *</label>
              <input @change="clearError('city')" :class="{'invalid-field': findInputError('city') }" v-model="city">
              <sub style="color: red;" v-if="findInputError('city')">{{findInputError('city').message}}</sub>
            </div>
          </div>
          <div>
            <label for="region">Bundesland *</label>
            <v-select @input="clearError('region')" :class="{'invalid-field': findInputError('region') }" :options="regions" v-model="region"></v-select>
            <sub style="color: red;" v-if="findInputError('region')">{{findInputError('region').message}}</sub>
          </div>
          <div class="info-section">
            <p>
              Bitte geben Sie mindestens eine Kontaktmöglichkeit an, die auf Impftermine.de veröffentlicht wird:
            </p>
          </div>
          <div>
            <label for="email">Kontakt E-Mail (wird veröffentlicht)</label>
            <input @change="clearError('email')" :class="{'invalid-field': findInputError('email') }" v-model="email">
            <sub style="color: red;" v-if="findInputError('email')">{{findInputError('email').message}}</sub>
          </div>
          <div>
            <label for="telephone">Kontakt Telefonnummer (wird veröffentlicht)</label>
            <input @change="clearError('telephone')" :class="{'invalid-field': findInputError('telephone') }" v-model="telephone">
            <sub style="color: red;" v-if="findInputError('telephone')">{{findInputError('telephone').message}}</sub>
          </div>
          <div>
            <label for="website">Kontakt Website (wird veröffentlicht)</label>
            <input @change="clearError('website')" :class="{'invalid-field': findInputError('website') }" v-model="website">
            <sub style="color: red;" v-if="findInputError('website')">{{findInputError('website').message}}</sub>
          </div>
          <div class="info-section">
            <p>
              Bitte geben Sie Ihren Kontakt für Rückfragen an (wird nicht veröffentlicht):
            </p>
          </div>
          <div class="inline-inputs">
            <div>
              <label for="contact_firstname">Vorname (wird nicht veröffentlicht) *</label>
              <input @change="clearError('contact_firstname')" :class="{'invalid-field': findInputError('contact_firstname') }" v-model="contact_firstname">
              <sub style="color: red;" v-if="findInputError('contact_firstname')">{{findInputError('contact_firstname').message}}</sub>
            </div>
            <div class="spacer"></div>
            <div>
              <label for="contact_lastname">Nachname (wird nicht veröffentlicht) *</label>
              <input @change="clearError('contact_lastname')" :class="{'invalid-field': findInputError('contact_lastname') }" v-model="contact_lastname">
              <sub style="color: red;" v-if="findInputError('contact_lastname')">{{findInputError('contact_lastname').message}}</sub>
            </div>
          </div>
          <div class="inline-inputs">
            <div>
              <label for="contact_telephone">Telefonnummer (wird nicht veröffentlicht) *</label>
              <input @change="clearError('contact_telephone')" :class="{'invalid-field': findInputError('contact_telephone') }" v-model="contact_telephone">
              <sub style="color: red;" v-if="findInputError('contact_telephone')">{{findInputError('contact_telephone').message}}</sub>
            </div>
            <div class="spacer"></div>
            <div>
              <label for="contact_email">E-Mail (wird nicht veröffentlicht) *</label>
              <input @change="clearError('contact_email')" :class="{'invalid-field': findInputError('contact_email') }" v-model="contact_email">
              <sub style="color: red;" v-if="findInputError('contact_email')">{{findInputError('contact_email').message}}</sub>
            </div>
          </div>
          <div class="check-inline"> 
            <label for="checkbox">Einwilligung für den Erhalt von Newsletters: Durch Anklicken der JA Checkbox willige ich ein, dass meine personenbezogenen Daten, wie Namen und Emailadresse zum Zweck der Zusendung von Informationen und Angeboten zu weiteren Produkten und Dienstleitungen von No-Q der Vertical Life GmbH verwendet werden dürfen (ca. 1 mal im Monat). Diese Einwilligung ist freiwillig und kann von mir jederzeit schriftlich mittels E-Mail an office@vertical-life.info ohne Angabe von Gründen für die Zukunft widerrufen werden. Als betroffene Person im Sinne der DSGVO haben ich gemäß Art 16 ff das Recht auf Berichtigung, Löschung, Einschränkung und Widerspruch im Zusammenhang mit der Verwendung meiner personenbezogenen Daten. Ich erteile meine Einwilligung:
              <input type="checkbox" id="checkbox" v-model="newsletter">
            </label>
          </div>
          <p style="margin-bottom: 20px;">
            <input class="noq-button__primary" :class="[disableSubmit ? 'noq-button__disabled' : 'noq-button__primary']" type="submit" value="Submit">
          </p>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
  import axios from 'axios';
  import vSelect from 'vue-select';

  import 'vue-select/dist/vue-select.css';

  export default {
    components: {
      vSelect
    },
    data() {
      return {
        errors: [],
        name: '',
        address: '',
        zip: '',
        city: '',
        regions: ["Baden-Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"],
        region: '',
        email: '',
        telephone: '',
        website: '',
        contact_firstname: '',
        contact_lastname: '',
        contact_telephone: '',
        contact_email: '',
        newsletter: false,
        formSubmitted: false,
        disableSubmit: false,

      }
    },
    methods:{
      validateForm() {
        if (this.name && this.address && this.zip && this.region && this.contact_firstname && this.contact_lastname && this.contact_telephone && this.contact_email) {
          return true
        } else {
          this.errors = [];

          if (!this.name) {
            this.errors.push({field: 'name', message: 'Name ist ein Pflichtfeld.'});
          }
          if (!this.address) {
            this.errors.push({field: 'address', message: 'Addresse ist ein Pflichtfeld.'});
          }
          if (!this.zip) {
            this.errors.push({field: 'zip', message: 'Postleitzahl ist ein Pflichtfeld.'});
          }
          if (!this.city) {
            this.errors.push({field: 'city', message: 'Stadt ist ein Pflichtfeld.'});
          }
          if (!this.region) {
            this.errors.push({field: 'region', message: 'Bundesland ist ein Pflichtfeld.'});
          }
          if (!this.contact_firstname) {
            this.errors.push({field: 'contact_firstname', message: 'Vorname ist ein Pflichtfeld.'});
          }
          if (!this.contact_lastname) {
            this.errors.push({field: 'contact_lastname', message: 'Nachname ist ein Pflichtfeld.'});
          }
          if (!this.contact_telephone) {
            this.errors.push({field: 'contact_telephone', message: 'Telefon ist ein Pflichtfeld.'});
          }
          if (!this.contact_email) {
            this.errors.push({field: 'contact_email', message: 'Email ist ein Pflichtfeld.'});
          }

          return false
        }
      },
      submitForm(e) {
        e.preventDefault();
        this.disableSubmit = true;

        if (this.validateForm()) {
          let that = this;
          let formData = new FormData();
          formData.append("name", this.name);
          formData.append("address", this.address);
          formData.append("zip", this.zip);
          formData.append("city", this.city);
          formData.append("region", this.region);
          formData.append("email", this.email);
          formData.append("telephone", this.telephone);
          formData.append("website", this.website);
          formData.append("contact_firstname", this.contact_firstname);
          formData.append("contact_lastname", this.contact_lastname);
          formData.append("contact_telephone", this.contact_telephone);
          formData.append("contact_email", this.contact_email);
          formData.append("newsletter", this.newsletter);

          axios.post(process.env.VUE_APP_NEW_VAC_LOCATION_SCRIPT_URL, formData)
            .then(function (response) {
              if (response.status == 200) {
                that.formSubmitted = true
              }
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          this.disableSubmit = false
        }
      },
      findInputError(fieldName) {
        if (!this.errors.length) {
          return false
        } else {
          let err = this.errors.find( err => err.field === fieldName )
          return err
        }
      },
      clearError(fieldName) {
        if (this.errors.length) {
          let idx = this.errors.findIndex(err => err.field === fieldName);
          if (idx >= 0)  this.errors.splice(idx, 1);
        }
        
      }
    }
  }
</script>

<style lang="scss" scoped >
  .thank-you-page {
    background-color: #F9FAFB;
    width: 100%;
    display: flex;
    justify-content: center;
    .ty-card {
      h2 {
        font-weight: 700;
      }
      margin: 60px 10%;
      padding: 60px;
      background-color: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      .check {
        height: 44px;
        margin-bottom: 30px;
      }
      .ty-button {
        border: 1px solid rgb(60,60,60);
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 16px;
        text-decoration: none;
        color: rgb(60,60,60);
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .inline-inputs {
    display: flex;
    justify-content: space-around;
    div {
      flex: 20;
    }
    .spacer {
      flex: 1;
    }
  }
  
  @media (max-width: 650px) {
    .inline-inputs {
      display: block;
      .spacer {
        display: none;
      }
    }
  }

  .nl-form {
    & label {
      display: block;
      margin: 14px 0 4px 0;

    }
    & input {
      width: 100%;
      line-height: 1.6;
      border-radius: 4px;
      border: 1px solid rgba(60,60,60, .26);
      padding: 6px 8px;
      display: block;
      box-sizing: border-box;
    }
  }
  .check-inline {
    
    & label, & input{
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

  }
  .info-section {
    margin: 40px 0;
  }
  .invalid-field {
    border: 1px solid rgba(256,0,0, .8) !important;
    border-radius: 4px;
  }
  .app-container {
    margin-left: 18px;
    margin-right: 18px;
  }
  .locator-container {
    font-family: "Fira Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #102A43;
    text-align: left;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-content-container {
    min-height: calc(100vh - 60px);
  }
  .vs__dropdown-toggle, .vs_search, .v-select {
    background: white !important;
  }
  .header {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    color: #102A43;
    font-size: 14px;
  }
  .header-left {
    font-size: 21px;
    font-weight: bold;
  }
  .content-container {
    margin-top: 26px;
  }
  .intro-text {
    font-size: 17.5px;
    color: #778088;
  }
  .cta-container {
    margin-top: 50px;
  }
  .cta-title {
    font-size: 24.5px;
    color: #102A43;
    font-weight: bold;
  }
  .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .cta-button-container {
    width: 100%;
    max-width: 336px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;
  }
  .cta-svg-container {
    display: flex;
    height: 140px;
    justify-content: center;
    align-items: center;
  }
  .cta-button {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #334F68;
    border-radius: 4px;
    font-size: 18px;
  }
  .sponsor-container {
    margin-top: 70px;
    font-weight: bold;
    font-size: 17.5px;
  }
  .sponsor-image {
    width: 100%;
    object-fit: contain;
  }
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .footer-container a {
    text-decoration: none;
    color: inherit
  }

  /* responsive */
  @media (min-width: 768px) {
    .locator-container {
      max-width: 700px;
    }

    .cta-buttons {
      flex-direction: row;
      justify-content: space-between;
    }
    .language-switch {
      margin-right: 0px;
    }
  }
</style>