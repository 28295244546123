<template>
  <div class="location-item-wrapper" :class="{ 'location-item-wrapper__is-active': locationItem.isActive }">
    <div class="location-item">
      <div
        class="location-item-logo"
        :style="{
          'background-image': `url(${getLogo}), url(${getDefaultLogo})`
        }"
      ></div>
      <div class="location-item-name-wrapper"
           :class="{
              'location-item-name-wrapper__full-width': !locationItem.availability
            }">
        <div class="location-item-name">
          {{ locationItem.name }}
        </div>
        <div v-if="availabilityBadgeText(locationItem)" style="padding: 6px 0px;">
          <span class="location-item-availability-badge" :style="{color: locationItem.free === 1 ? 'red' : ''}">
            {{ availabilityBadgeText(locationItem) }}
          </span>
        </div>
        <div class="location-item-address">
          <span
            class="location-item-address-name"
            :class="{
              'location-item-address-name__fixed-width': isWidget
            }"
          >
            {{ locationItem.address }}, {{ locationItem.city }}
          </span>
          <span v-if="recentLocationDistance" class="location-item-address-distance">
            &#8226; {{ recentLocationDistance }}
          </span>
        </div>
        <div v-if="allIntegrations.length > 0" class="location-item-integrations">
          <div v-if="locationItem.appIntegrations.includes('cwa')"
            class="app-integration-icon"
            :style="{
              'background-image': `url(${require ('@/assets/icons/cwa_app_icon.png')}`
            }"
          ></div>
          <div v-if="locationItem.appIntegrations.includes('dcc')"
            class="app-integration-icon"
            :style="{
              'background-image': `url(${require ('@/assets/icons/dcc_icon.png')}`
            }"
          ></div>
          <div v-if="locationItem.appIntegrations.includes('luca')"
            class="app-integration-icon"
            :style="{
              'background-image': `url(${require ('@/assets/icons/luca_app_icon.png')}`
            }"
          ></div>
        </div>
      </div>
      <div class="location-item-btn-wrapper" v-if="!isWidget && locationItem.availability">
        <a
          href="#"
          @click="navToBooking(bookingLink(locationItem, dateTimeFilter.date_range.start))"
          class="noq-button noq-button__primary noq-button__sm"
          :class="{ 'noq-button__disabled': locationItem.first_available === null }"
          @click.stop
        >
          <template v-if="locationItem.booketOut">
            {{ $t('components.LocationItem.booketOut') }}
          </template>
          <template v-else-if="locationItem.free > 0 || locationItem.first_available != null">
            {{ $t('components.LocationItem.bookNow') }}
          </template>
          <template v-else>
            {{ $t('components.LocationItem.notAvailable') }}
          </template>
        </a>
      </div>
      <div class="location-item-values-wrapper" v-else-if="locationItem.availability">
        <div class="location-item-values-free">
          {{ locationItem.free }}
        </div>
        <div class="location-item-values-label">
          free
        </div>
      </div>
      <div class="contacts-toggle-btn-wrapper" v-else>
        <a class="contacts-toggle-btn" 
          href="#"
          @click="toggleContactsBar"
          @click.stop >
          <i class="noq-icon" :class="show_contacts_bar ? 'noq-arrow-down' : 'noq-arrow-right'"/>
        </a>
      </div>
    </div>
    <contacts-bar :location-item="locationItem" v-if="!locationItem.availability && show_contacts_bar" />
  </div>
</template>

<script>
import urlsMixin from '@/mixins/url_mixin'
import ContactsBar from '@/components/ContactsBar.vue'
import { DateTime } from 'luxon'

export default {
  components: {
    ContactsBar
  },
  mixins: [urlsMixin],
  props: {
    dateTimeFilter: {
      type: Object,
      required: true
    },
    locationItem: {
      type: Object,
      required: true
    },
    recentLocation: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    isWidget: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      show_contacts_bar: false
    }
  },

  computed: {
    showAvailability(){
      return process.env.VUE_APP_HIDE_AVAILABILITY !== "true"
    },
    getLogo() {
      return `${process.env.VUE_APP_LOGO_BASE_URL}${this.locationItem.logo}.jpg`
    },
    getDefaultLogo() {
      return require('@/assets/images/no-q_no-pharma-logo.svg')
    },
    recentLocationDistance() {
      if (!!this.recentLocation && 'distance' in this.locationItem) {
        return `${this.locationItem.distance}km`
      } else {
        return this.getDistanceFromLatLonInKm
      }
    },
    // https://stackoverflow.com/a/27943
    getDistanceFromLatLonInKm() {
      const lat1 = this.locationItem.lat
      const lon1 = this.locationItem.lng
      let lat2
      let lon2

      if (!this.recentLocation) {
        return false
      } else if (!!this.recentLocation && 'lat' in this.recentLocation && 'lng' in this.recentLocation) {
        lat2 = this.recentLocation.lat
        lon2 = this.recentLocation.lng
      } else {
        lat2 = this.recentLocation.geometry.location.lat()
        lon2 = this.recentLocation.geometry.location.lng()
      }

      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      d = Number((d).toFixed(2))
      return `${d} km`;
    },
    allIntegrations() {
      let ai = []
      let integrationString = process.env.VUE_APP_INTEGRATIONS
      
      if (integrationString && integrationString.length > 0) {
        ai = process.env.VUE_APP_INTEGRATIONS.split(",")
      }

      return ai
    },
  },
  methods: {
    availabilityBadgeText(locationItem) {
      if (!locationItem.first_available || !locationItem.availability) return "";

      const availableTomorrow = DateTime.now().plus({days: 1}).hasSame(locationItem.first_available, 'day')
      if (locationItem.free === 1) { 
        return `${locationItem.free} Platz heute verfügbar`
      } else if (locationItem.free > 0) {
        return `${locationItem.free} Plätze heute verfügbar`
      } else if (availableTomorrow) {
        return "Verfügbar ab morgen"
      } else {
        return `Verfügbar ab ${locationItem.first_available.toLocaleString()}` 
      }  
    },
    navToBooking(url) {
      window.open(url, '_blank');
      const event_id = process.env.VUE_APP_GTAG_NAV_TO_NOQ_EVENT_ID
      if (this.$gtag && event_id) {
        this.$gtag('event', 'conversion', {
          'send_to': event_id,
        });
      }
    },
    // https://stackoverflow.com/a/27943
    deg2rad(deg) {
      return deg * (Math.PI/180)
    },

    locationItemClicked() {
      this.$emit('location-click', this.locationItem)
    },

    toggleContactsBar(){
      this.show_contacts_bar = !this.show_contacts_bar
    }
  }
}
</script>

<style lang="scss">

.location-item-wrapper {
  background-color: $color_white;
  margin-bottom: 2px;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &__is-active {
    background-color: $color_dark-gray;
    cursor: pointer;
  }
}

.location-item {
  height: 68px;
  padding: $spacing-m $spacing-s $spacing-m $spacing-xs;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .location-item-logo {
    float: left;
    margin-right: $spacing-s;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .app-integration-icon {
    display: inline-block;
    margin-right: $spacing-3xs;
    width: 18px;
    height: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .location-item-name-wrapper {
    float: left;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 300px;

    &__full-width {
      max-width: 420px;
    }

    @media (max-width: $breakpoint-tablet) {
      width: 60%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-m) {
      width: 55%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-s) {
      width: 49%;
      max-width: unset;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .location-item-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: $color_gray;
      @include text_lg;
      margin-bottom: $spacing-3xs;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .location-item-address {
      @include text_sm;
      overflow: hidden;
      white-space: nowrap;

      .location-item-address-name {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &__fixed-width {
          max-width: 140px;
        }
      }

      .location-item-address-distance {
        display: inline-block;
        vertical-align: top;
        color: $color_black;
        padding-left: $spacing-4xs;
      }

    }
  }

  .contacts-toggle-btn-wrapper {
    margin-left: $spacing-s;
    margin-right: $spacing-s;
    width: 80px;
    text-align: right;
  }

  .location-item-btn-wrapper {
    margin-left: $spacing-s;
  }

  .location-item-values-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: $spacing-l;

    .location-item-values-free {
      @include text_lg;
      margin-bottom: $spacing-3xs;
    }

    .location-item-values-label {
      @include text_sm;
      color: $color_gray;
    }
  }
}
.location-item-availability-badge {
    text-decoration: none;
    color: #102A43;
    background-color: #D9E2EC;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
  }


</style>
