<template>
  <div class="noq-widget-description-component">
    <h4>
      {{ $t('components.WidgetDescription.noqWidgetBuilder') }}
    </h4>
    <p>
      {{ $t('components.WidgetDescription.paragraph_1') }}
    </p>
    <div class="noq-two-columns">
      <div class="noq-column">
        <div class="noq-column-img">
          <img :src="require('@/assets/images/widget-location-icon.png')">
        </div>
        <h4 class="noq-column-title">
          {{ $t('components.WidgetDescription.chooseYourLocationTitle') }}
        </h4>
        <div class="noq-column-description">
          {{ $t('components.WidgetDescription.chooseYourLocationDescr') }}
        </div>
      </div>
      <div class="noq-column">
        <div class="noq-column-img">
          <img :src="require('@/assets/images/widget-customise-icon.png')">
        </div>
        <h4 class="noq-column-title">
          {{ $t('components.WidgetDescription.customiseTitle') }}
        </h4>
        <div class="noq-column-description">
          {{ $t('components.WidgetDescription.customiseDescr') }}
        </div>
      </div>
    </div>
    <div class="noq-widget-description-controls-wrapper">
      <div class="noq-button noq-button__primary" @click="$emit('show-constructor')">
        {{ $t('components.WidgetDescription.buildYourWidget') }}
      </div>
    </div>
    <!-- <h4>
      {{ $t('components.WidgetDescription.widgetUsageExamples') }}
    </h4>
    <div class="widget-description-example-wrapper">
      <img :src="require('@/assets/images/widget-example.png')">
    </div>
    <div class="noq-widget-description-controls-wrapper">
      <div class="noq-button noq-button__primary" @click="$emit('show-constructor')">
        {{ $t('components.WidgetDescription.buildYourWidget') }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  computed: {
    bodyMapImage() {
      return require('@/assets/images/no-q_home_image_map.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-widget-description-component {
  @include text_lg;
  color: $color_gray;

  h4 {
    color: $color_black;
  }

  .noq-two-columns {
    margin-top: $spacing-3xl;
    margin-bottom: $spacing-4xl;

    .noq-column {
      display: inline-block;
      width: 50%;
      text-align: center;
      vertical-align: top;

      .noq-column-description {
        @include text_lg;
        color: $color_gray;
      }
    }
  }

  .noq-widget-description-controls-wrapper {
    text-align: center;
  }

  .widget-description-example-wrapper {
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}
</style>
