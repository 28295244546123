<template>
  <div class="add-item-wrapper">
    <a target="_blank"
       :href="formLink">
      <div class="add-item">
        <div
          class="add-item-logo"
          :style="{
            'background-image': `url(${getFormLinkLogo})`
          }"
        ></div>
        <div class="add-item-name-wrapper">
          <div class="add-item-name">
            {{ $t('components.LocationsList.formLinkText') }}
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  computed: {
    getFormLinkLogo() {
      return require('@/assets/images/noq-plus.svg')
    },
    formLink() {
      return "#/new-location"
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">

.add-item-wrapper {
  background-color: $color_white;
  margin-bottom: 2px;

  &:hover,
  &__is-active {
    background-color: $color_dark-gray;
    cursor: pointer;
  }
}

.add-item {
  padding: $spacing-m $spacing-s $spacing-m $spacing-xs;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .add-item-logo {
    float: left;
    margin-right: $spacing-s;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .add-item-name-wrapper {
    float: left;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 370px;

    @media (max-width: $breakpoint-tablet) {
      width: 60%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-m) {
      width: 55%;
      max-width: unset;
    }

    @media (max-width: $breakpoint-mobile-s) {
      width: 49%;
      max-width: unset;
    }

    .add-item-name {
      @include text_lg;
      margin-bottom: $spacing-3xs;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
