<template>
  <div class="noq-widget-constructor-component">
    <h4>{{ $t('components.WidgetConstructor.insertYourAddress') }}</h4>
    <div class="m-b-m">
      {{ $t('components.WidgetConstructor.insertYourAddressDescr') }}
    </div>
    <map-search
      :show-current-location-control="false"
      @search-item-selected="setSearchLocation"
      @location-query-cleared="setSearchLocation"
      @my-location-detected="setSearchLocation"
    />
    <div v-if="searchLocation" class="noq-widget-preview">
      <h4>
        {{ $t('components.WidgetConstructor.customise') }}
      </h4>
      <div class="noq-widget-builder-customize-wrapper">
        <!-- FORMAT RADIO BUTTONS -->
        <div class="noq-widget-builder-customize-format-wrapper">
          <div class="noq-widget-builder-customize-format-title">
            {{ $t('components.WidgetConstructor.format') }}
          </div>
          <radio-button-group
            :group-name="'format'"
            :radio-buttons="widgetFormats"
            :is-buttons-splitted="false"
            :is-full-width="true"
            :size="'big'"
            @value-changed="updateWidgetFormat"
          />
        </div>
        <!-- HEIGHT RADIO BUTTONS -->
        <div  
          class="noq-widget-builder-customize-height-wrapper noq-widget-builder-customize-values"
        >
          <div class="noq-widget-builder-customize-values-title">
            {{ $t('components.WidgetConstructor.height') }}
          </div>
          <radio-button-group
            :group-name="'height'"
            :radio-buttons="widgetHeights"
            @value-changed="updateWidgetHeight"
          />
        </div>
        <!-- MAP ZOOM LEVEL RADIO BUTTONS -->
        <div
          v-if="showWidgetMapZoomLevelSelection"
          class="noq-widget-builder-customize-map-zoom-wrapper noq-widget-builder-customize-values"
        >
          <div class="noq-widget-builder-customize-values-title">
            {{ $t('components.WidgetConstructor.mapZoomLevel') }}
          </div>
          <radio-button-group
            :group-name="'mapZoomLevel'"
            :radio-buttons="widgetMapZoomLevels"
            @value-changed="updateWidgetMapZoomLevel"
          />
        </div>
      </div>
      <div class="noq-widget-create-code-controls-wrapper">
        <div class="noq-button noq-button__primary" @click="$emit('show-embed-code', embedCode)">
          {{ $t('components.WidgetConstructor.createEmbedCode') }}
        </div>
      </div>
      <h4>
        {{ $t('components.WidgetConstructor.preview') }}
      </h4>
      <div class="noq-widget-preview-wrapper">
        <div
          class="noq-widget-preview-example"
          :class="{
            'noq-widget-preview-example__with-map': widgetFormatValue === 'horizontal'
          }"
        >
          <widget
            :location="searchLocation"
            :format="widgetFormatValue"
            :height="widgetHeightValue"
            :zoom-level="widgetMapZoomLevelValue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapSearch from '@/components/MapSearch.vue'
import RadioButtonGroup from '@/components/RadioButtonGroup.vue'
import Widget from '@/components/Widget.vue'

export default {
  components: {
    MapSearch,
    RadioButtonGroup,
    Widget
  },
  data() {
    return {
      searchLocation: null,
      widgetFormatValue: 'vertical',
      widgetHeightValue: 450,
      widgetHeights: [
        {
          label: '740 px',
          value: 740,
          selected: false
        },
        {
          label: '600 px',
          value: 600,
          selected: false
        },
        {
          label: '450 px',
          value: 450,
          selected: true
        },
        {
          label: '300 px',
          value: 300,
          selected: false
        }
      ],
      widgetMapZoomLevelValue: 12,
      widgetMapZoomLevels: [
        {
          label: '12',
          value: 12,
          selected: true
        },
        {
          label: '13',
          value: 13,
          selected: false
        },
        {
          label: '14',
          value: 14,
          selected: false
        }
      ]
    }
  },
  computed: {
    embedCode() {
      const hostName = location.protocol + "//" + location.host
      const lat = this.searchLocation.geometry.location.lat()
      const lng = this.searchLocation.geometry.location.lng()
      const coords = `${lat},${lng}`

      let src = `${hostName}/#/widget?location=${coords}&format=${this.widgetFormatValue}&height=${this.widgetHeightValue}`
      let width = '300px'
      if (this.widgetFormatValue === 'horizontal') {
        src = `${src}&zoomLevel=${this.widgetMapZoomLevelValue}`
        width = '100%'
      }

      return `
        <iframe
          src="${src}"
          height="${this.widgetHeightValue}px"
          width="${width}"
        ></iframe>
      `
    },
    showWidgetMapZoomLevelSelection() {
      return this.widgetFormatValue === 'horizontal'
    },
    widgetFormats() {
      return [
        {
          label: this.$t('components.WidgetConstructor.vertical'),
          value: 'vertical',
          selected: true
        },
        {
          label: this.$t('components.WidgetConstructor.horizontal'),
          value: 'horizontal',
          selected: false
        }
      ]
    }
  },
  watch: {
    height(newVal) {
      this.setWidgetHeight(newVal)
    }
  },
  methods: {
    setSearchLocation(location) {
      this.searchLocation = location
    },
    updateWidgetFormat(formatValue) {
      this.widgetFormatValue = formatValue
    },
    updateWidgetHeight(heightValue) {
      this.widgetHeightValue = heightValue
    },
    updateWidgetMapZoomLevel(mapZoomLevelValue) {
      this.widgetMapZoomLevelValue = mapZoomLevelValue
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-widget-constructor-component {
  h4 {
    margin-top: 0;
    margin-bottom: $spacing-xl;
  }

  .noq-widget-preview {

    .noq-widget-locator-link {
      display: block;
      margin-top: $spacing-s;
    }

    h4 {
      margin-top: $spacing-4xl;
      margin-bottom: $spacing-s;
    }

    .noq-widget-builder-customize-wrapper {
      .noq-widget-builder-customize-format-wrapper {
        .noq-widget-builder-customize-format-title {
          @include text_lg;
          margin-bottom: $spacing-xs;
        }
      }

      .noq-widget-builder-customize-values {
        margin-top: $spacing-l;

        .noq-widget-builder-customize-values-title {
          @include text_lg;
          margin-bottom: $spacing-xs;
        }
      }
    }

    .noq-widget-preview-wrapper {
      .noq-widget-preview-example {
        width: 300px;
        margin: 0 auto;
        border: 1px solid $color_forms-border;

        &__with-map {
          width: 100%;
        }
      }
    }

    .noq-widget-create-code-controls-wrapper {
      margin-top: $spacing-xxl;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.noq-widget-builder-page {
  .location-search {
    width: 100%;
    margin: 0;

    .location-search-input {
      border: 1px solid $color_forms-border;
      padding-right: 44px;
    }

    .pac-container {
      @media (max-width: $breakpoint-tablet) {
        position: relative;
        top: 0;
      }
    }
  }

  .noq-widget-builder-customize-values {
    .noq-radio-toolbar {
      .noq-radio-button {
        margin-right: $spacing-xs;
      }
    }
  }
}
</style>
