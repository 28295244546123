<template>
  <div v-if="!isCookiesPolicyAccepted" class="noq-cookies-policy">
    {{ $t('components.CookiesPolicyInfo.cookiesPolicyPrefix') }}
    <a target="_blank" href="https://www.vertical-life.info/legal/no-q/cookies-policy">
      {{ $t('components.CookiesPolicyInfo.policyLink') }}
    </a>
    <i class="noq-icon noq-x xs-size" @click="acceptCookiesPolicy" />
  </div>
</template>

<script>
const COOKIES_POLICY = 'cookies-policy-info'

export default {
  data() {
    return {
      isCookiesPolicyAccepted: false
    }
  },
  mounted() {
    this.isCookiesPolicyAccepted = !!this.$cookies.get(COOKIES_POLICY)
  },
  methods: {
    acceptCookiesPolicy() {
      this.$cookies.set(COOKIES_POLICY, true, 60 * 60 * 24 * 365)
      this.isCookiesPolicyAccepted = true
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-cookies-policy {
  @include text_sm;
  position: fixed;
  bottom: $spacing-m;
  right: $spacing-s;
  padding: $spacing-3xs $spacing-xs;
  background-color: $color_white;
  border: 1px solid $color_forms-border;
  border-radius: 4px;

  @media (max-width: $breakpoint-tablet) {
    transform: translateX(-50%);
    left: 50%;
    width: fit-content;
  }

  .noq-icon {
    cursor: pointer;
  }
}
</style>
