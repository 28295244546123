<template>
  <div class="search-item" @click="$emit('recent-search-selected', recentItem)">
    <div class="search-item-icon">
      <i class="noq-icon noq-clock" />
    </div>
    <div class="search-item-label search-item-label__recent">
      {{ recentItem }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recentItem: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-item {
  padding: $spacing-xs;

  &:hover {
    background-color: $color_dark-gray;
    cursor: pointer;
  }

  .search-item-icon {
    display: inline-block;
    margin-right: $spacing-xs;
  }

  .search-item-label {
    @include text_lg;
    display: inline-block;
    color: $color_black;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    max-width: 85%;

    &__recent {
      color: $color_gray;
    }
  }
}
</style>
