<template>
  <div class="app-container">
    <div class="locator-container">
      <div class="header-content-container">
        <div class="header">
          <div class="header-left">{{ $t('views.Home.headerText') }}</div>
          <div id="language-dropdown-toggle" class="header-right">
            <language-selector></language-selector>
          </div>
        </div>
        <div class="content-container">
          <div class="intro-text">{{ $t('views.TestVacLanding.intro') }}</div>
          <div class="cta-container">
            <div class="cta-title">{{ $t('views.TestVacLanding.ctaTitle') }}</div>
            <div class="cta-buttons">
              <a href="https://app.no-q.info/bookingtestcovid/locator#/" class="cta-button-container">
                <div class="cta-svg-container">
                  <svg width="67" height="80" viewBox="0 0 67 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.7395 76C14.5549 76 10.1679 71.6 10.1679 66.4V65.6H16.1501V61.6H10.1679V56.8H16.1501V52.8H10.1679V48H16.1501V44H10.1679V39.6H16.1501V35.6H10.1679V29.2H29.7098V36.4H33.6979V29.2H39.2813V7.6H33.6979V0H5.781V7.6H0.596436V29.2H6.17982V66.4C6.17982 74 12.5608 80 19.7395 80C25.7217 80 30.9062 76.4 32.9003 70.8L29.311 69.6C27.7157 73.2 24.1264 76 19.7395 76ZM9.37032 4H29.311V7.6H9.37032V4ZM4.58457 25.2V11.6H5.38219H33.2991H34.8944V25.2H33.6979H6.17982H4.58457Z" fill="#334F68"/>
                    <path d="M66.4006 57.2V49.2H62.4125V51.2H59.222C58.8232 49.2 58.0256 47.2 56.8291 45.6L58.8232 43.6L60.4184 45.2L63.2101 42.4L57.6267 36.8L54.8351 39.6L56.4303 41.2L54.4362 43.2C52.841 42 50.8469 41.2 48.8529 40.8V37.6H50.8469V33.6H42.8707V37.6H44.8647V40.8C42.8707 41.2 40.8766 42 39.2813 43.2L37.2873 41.2L38.8825 39.6L35.692 36.4L30.1086 42L32.9003 44.8L34.4956 43.2L36.4897 46C35.2932 47.6 34.4956 49.6 34.0968 51.6H31.3051V49.6H27.317V57.6H31.3051V55.6H34.4956C34.8944 57.6 35.692 59.6 36.8885 61.2L34.8944 63.2L33.2992 61.6L30.5075 64.4L36.0908 70L38.8825 67.2L37.2873 65.6L39.2813 63.6C40.8766 64.8 42.8707 65.6 44.8647 66V69.2H42.8707V73.2H50.8469V69.2H48.8529V66C50.8469 65.6 52.841 64.8 54.4362 63.6L56.4303 65.6L54.8351 67.2L57.6267 70L63.2101 64.4L60.4184 61.6L58.8232 63.2L56.8291 61.2C58.0256 59.6 58.8232 57.6 59.222 55.6H62.4125V57.6H66.4006V57.2ZM46.8588 62C42.073 62 38.0849 58 38.0849 53.2C38.0849 48.4 42.073 44.4 46.8588 44.4C51.6446 44.4 55.6327 48.4 55.6327 53.2C55.6327 58 51.6446 62 46.8588 62Z" fill="#334F68"/>
                  </svg>
                </div>
                <div class="cta-button">{{ $t('views.TestVacLanding.ctaBookTest') }}</div>
              </a>
              <a href="#/list" class="cta-button-container">
                <div class="cta-svg-container">
                  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M80.3798 77.2139L60.5384 57.3134L62.9193 54.9254L55.7764 47.7612C56.9669 43.7811 55.7764 39.403 52.9986 36.2189L29.1889 12.3383L26.4111 15.1244L21.2523 9.95025L24.0301 7.16418L17.284 0L0.617188 16.7164L7.76011 23.8806L10.5379 21.0945L15.6967 26.2687L12.5221 28.6567L36.3318 52.5373C38.7128 54.9254 41.4906 56.1194 44.6652 56.1194C45.8557 56.1194 47.0462 55.7214 47.8398 55.7214L54.5859 62.4876L56.9669 60.0995L76.8083 80L80.3798 77.2139ZM7.76011 17.9104L6.17279 16.7164L17.284 5.57214L18.8713 7.16418L7.76011 17.9104ZM18.4745 12.7363L23.6333 17.9104L18.4745 23.0846L13.3157 17.9104L18.4745 12.7363ZM39.5064 49.7512L18.0777 28.6567L28.792 17.9104L31.173 20.2985L27.2047 24.6766L29.9825 27.4627L34.3476 23.0846L36.3318 25.0746L31.9667 29.8507L34.7445 32.6368L39.1096 28.2587L41.0937 30.2488L37.1254 34.6269L39.9032 37.4129L44.2684 33.0348L46.2525 35.0249L42.2842 39.801L45.062 42.5871L49.4271 38.209L50.2208 39.005C53.3954 42.1891 53.3954 46.9652 50.2208 49.7512C47.0462 52.9353 42.2842 52.9353 39.5064 49.7512ZM51.8081 53.7313L54.1891 51.3433L56.9669 54.1294L54.5859 56.5174L51.8081 53.7313Z" fill="#334F68"/>
                  </svg>
                </div>
                <div class="cta-button">{{ $t('views.TestVacLanding.ctaBookVaccination') }}</div>
              </a>
            </div>
          </div>
          <div class="sponsor-container">
            <div>{{ $t('views.TestVacLanding.support') }}</div>
            <img class="sponsor-image" src="/st_vac_sponsor.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="footer-container">
        <div>
          <a href="https://www.no-q.info/" target="_blank">{{ $t('views.Home.serviceProvidedByNoQ') }}</a> •
          <a href="https://www.no-q.info/impressum/" target="_blank">{{ $t('views.Home.imprint') }}</a> •
          <a href="https://www.no-q.info/datenschutz/" target="_blank">{{ $t('views.Home.conditions') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
  components: {
    LanguageSelector
  }
}
</script>

<style scoped >
  .app-container {
    margin-left: 18px;
    margin-right: 18px;
  }
  .locator-container {
    font-family: "Fira Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #102A43;
    text-align: left;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .header-content-container {
    min-height: calc(100vh - 60px);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    color: #102A43;
    font-size: 14px;
  }
  .header-left {
    font-weight: bold;
  }
  .header-right {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .content-container {
    margin-top: 80px;
  }
  .intro-text {
    font-size: 17.5px;
    color: #778088;
  }
  .cta-container {
    margin-top: 50px;
  }
  .cta-title {
    font-size: 24.5px;
    color: #102A43;
    font-weight: bold;
  }
  .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .cta-button-container {
    width: 100%;
    max-width: 336px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;
  }
  .cta-svg-container {
    display: flex;
    height: 140px;
    justify-content: center;
    align-items: center;
  }
  .cta-button {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #334F68;
    border-radius: 4px;
    font-size: 18px;
  }
  .sponsor-container {
    margin-top: 70px;
    font-weight: bold;
    font-size: 17.5px;
  }
  .sponsor-image {
    width: 100%;
    object-fit: contain;
  }
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .footer-container a {
    text-decoration: none;
    color: inherit
  }

  /* language box */
  .language-switch-container {
    position: absolute;
    top: 50px;
    display: flex;
    justify-content: flex-end;
  }
  .language-switch {
    display: none;
    margin-right: 20px;
    margin-top: 5px;
    position: relative;
    background-color: white;
    border: 1px solid #c2c6c9;
    border-radius: 4px;
  }
  .language-switch.show {
    display: block;
  }
  .language-button {
    padding: 5px;
    width: 75px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: inherit;
  }
  .language-button:hover {
    background-color: #c2c6c9;
  }

  /* responsive */
  @media (min-width: 768px) {
    .locator-container {
      max-width: 700px;
    }

    .cta-buttons {
      flex-direction: row;
      justify-content: space-between;
    }
    .language-switch {
      margin-right: 0px;
    }
  }
</style>