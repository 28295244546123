<template>
  <select
    v-if="supportedLanguages.length > 1"
    name="language"
    @change="changeLanguage"
  >
    <option
      v-for="lang in supportedLanguages"
      :key="lang"
      :selected="isCurrentLanguage(lang)"
      :class="{ 'is-selected': isCurrentLanguage(lang) }"
      :value="lang"
    >
      {{ getLangLabel(lang) }}
    </option>
  </select>
</template>

<script>
import { Trans } from '@/plugins/translation'

export default {
  computed: {
    supportedLanguages () {
      return Trans.supportedLanguages
    },
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  methods: {
    changeLanguage(e) {
      // this.$i18n.locale = e.target.value
      const lang = e.target.value
      // const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang)
        // .then(() => {
        //   this.$router.push(to.location)
        // })
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    },
    getLangLabel(lang) {
      return Trans.getLangLabel(lang)
    }
  }
}
</script>

<style lang="scss" scoped>
select {
    position: relative;
    background: transparent;
    background-image: none;
    outline: none;
    color: $color_black;
    cursor: pointer;
    border: none;
    // This is so hacky.. (for Firefox)
    // Removes the default select arrow.
    text-overflow: '';
    // text-indent: 5px;
    // End of brutal hack

    // @include appearance(none);

    &[multiple=multiple] {
        height: auto;
        border: 1px solid #cbcbcb;
        border-radius: 3px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.07);

        // @include single-transition(box-shadow, 0.2s);

        &:hover {
            border-color: #cccccc;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        }
    }

    option[disabled] {
        color: #eeeeee;
        text-shadow: none;
        border: none;
    }
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #888888;
}

select::-ms-expand {
    display: none;
}
</style>
