import axios from 'axios'
import { DateTime } from "luxon";

const TAGS = process.env.VUE_APP_QUERY_TAGS.split(',')

export default {
  data(){
    return {
      elasticUrl: process.env.VUE_APP_ELASTIC_URL,
      dateTimeFilter: null,
    }
  },
  methods: {
    async getLocationsByDateTime(dateTimeFilter) {
      this.dateTimeFilter = dateTimeFilter
      let query = this.buildElasticLocationsQuery()
      return axios.get(this.elasticUrl, {
        params: {
          source: JSON.stringify(query),
          source_content_type: 'application/json'
        },
      })
      .then(resp => this.normaliseElasticData(resp.data))
      .catch(err => console.log(err))
    },
    normaliseElasticData(data) {
      const locationArr = data.aggregations?.locations.buckets

      if (!locationArr) return null;
    
      return locationArr.map(loc => {
        
        const locInfo = loc.gym.hits.hits[0]._source

        return {
          slug: locInfo.slug,
          lat: locInfo.lat,
          lng: locInfo.lng,
          name: locInfo.name,
          free: this.free_spots_today(locInfo.slug, loc.availability_per_day.buckets[0]),
          first_available: this.first_available(loc.availability_per_day.buckets),
          address: locInfo.address,
          zip: locInfo.zip,
          city: locInfo.city,
          country: locInfo.country,
          booketOut: false,
          appIntegrations: locInfo.app_integrations,
          slim: locInfo.slim,
          availability: locInfo.availability,
          phone: locInfo.phone,
          logo: locInfo.logo,
          vaccines: locInfo.vaccines,
          age_groups: locInfo.age_groups,
          slimTestbuchen: locInfo.slim_testbuchen,
          website: locInfo.website,
          prices: locInfo.prices
        }
      })
    },
    free_spots_today(slug, first_bucket) {
      if (!first_bucket) return 0
      const today = DateTime.now().startOf('day')
      const bucket_date = DateTime.fromISO(first_bucket.key_as_string);
      const isToday = bucket_date.hasSame(today, 'day');
      if (isToday) return first_bucket?.free_spots_sum?.value
      return 0
    },
    first_available(availability_buckets) {
      let faDate = null
      for (const v of availability_buckets) {
        if (v.free_spots_sum.value > 0 ) {
          faDate = DateTime.fromMillis(v.key);
          break;
        }
      }
      return faDate
    },
    buildElasticLocationsQuery() {
      let filters = []
    
      let appFilter = this.dateTimeFilter.integrations && this.dateTimeFilter.integrations.length > 0
      let vaccineFilter = this.dateTimeFilter.vaccines && this.dateTimeFilter.vaccines.length > 0
      let ageFilter = this.dateTimeFilter.age_groups && this.dateTimeFilter.age_groups.length > 0

      if (appFilter) {
        filters.push({
          "terms": {"app_integrations": this.dateTimeFilter.integrations}
        })
      }
      if (vaccineFilter) {
        let vax_filter = []
        this.dateTimeFilter.vaccines.forEach(e => vax_filter.push({"terms":{"vaccines": [e]}}))
        filters.push({
          "terms": {"vaccines": this.dateTimeFilter.vaccines}
        })
      }
      if (ageFilter) {
        let age_filter = [];
        this.dateTimeFilter.age_groups.forEach((e) =>
          age_filter.push({ terms: { age_groups: [e] } })
        );
        filters.push({
          terms: { age_groups: this.dateTimeFilter.age_groups },
        });
      }
      if (this.dateTimeFilter.service) {
        filters.push({
          term: { "service_name.keyword": this.dateTimeFilter.service },
        });
      }

      return {
        "size": 0,
        "query": { "bool": { "filter": [
          ...filters,
          {"terms": {"tags": TAGS}},
          {"bool": { "should": [
            {"bool": {"must": [
              { "range": {
                "max_booking_time": {
                  "gte": "now"
                }
              }},
              { "range": {
                "min_booking_date": {
                  "lte": "now",
                }
              }},
              { "range": {
                "checkin_at": {
                  "gte": this.dateTimeFilter.date_range.start,
                  "lte": this.dateTimeFilter.date_range.end
                }
              }}
            ]}},
            {"term": {"placeholder": true}}
          ]}}
        ]}}, 
        "aggs": {
          "locations": {
            "terms": {
              "field": "gym_id",
              "size": 10000
            },
            "aggs": {
              "gym": {
                "top_hits": {
                  "size":1
                }
              },
              "first_available_date": {
                "min": {
                  "field": "checkin_at"
                }
              },
              "availability_per_day": {
                "date_histogram": {
                  "field": "checkin_at",
                  "calendar_interval": "day",
                  "time_zone": "Europe/Rome",
                  "min_doc_count": 1
                },
                "aggs": {
                  "free_spots_sum": {
                    "sum": {
                      "field": "free_spots"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  }
}