<template>
  <div class="noq-widget-builder-page">
    <div class="noq-widget-builder-header">
      <div class="noq-widget-builder-header-helper">
        <header-info />
      </div>
    </div>
    <div v-if="stepNumber > 1" class="widget-step-back-controls" >
      <h6 @click="stepBack()">
        <i class="noq-icon noq-arrow-left"></i>Back
      </h6>
    </div>
    <div
      class="noq-widget-builder-body"
      :class="{ 'noq-widget-builder-body__white-background': stepNumber > 1 }"
    >
      <template v-if="false">
        <widget-description @show-constructor="setConstructorVisible" />
      </template>
      <div v-show="stepNumber === 1">
        <widget-constructor @show-embed-code="showEmbedCodeStep"/>
      </div>
      <template v-if="stepNumber === 2">
        <widget-embed-code :embed-code="embedCode" />
      </template>
    </div>
    <footer-info />
  </div>
</template>

<script>
import FooterInfo from '@/components/FooterInfo.vue'
import HeaderInfo from '@/components/HeaderInfo.vue'
import WidgetConstructor from '@/components/WidgetConstructor.vue'
import WidgetDescription from '@/components/WidgetDescription.vue'
import WidgetEmbedCode from '@/components/WidgetEmbedCode.vue'

export default {
  components: {
    FooterInfo,
    HeaderInfo,
    WidgetConstructor,
    WidgetDescription,
    WidgetEmbedCode
  },
  data() {
    return {
      stepNumber: 1,
      embedCode: ''
    }
  },
  methods: {
    stepBack() {
      if (this.stepNumber > 1) {
        this.stepNumber--
      }
    },
    // setConstructorVisible() {
    //   this.stepNumber = 2
    // },
    showEmbedCodeStep(embedCode) {
      this.embedCode = embedCode
      this.stepNumber = 2
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-widget-builder-page {
  margin: 0 auto;
  position: relative;
  min-height: 100%;
  margin-bottom: -21px;

  .noq-widget-builder-header {
    background-color: $color_white;

    .noq-widget-builder-header-helper {
      margin: 0 auto;
      max-width: 694px;
      padding: $spacing-s 0;
    }
  }

  .widget-step-back-controls {
    max-width: 758px;
    margin: 0 auto;
    height: 60px;

    h6 {
      color: $color_black;
      line-height: 60px;
      cursor: pointer;

      .noq-icon {
        margin-right: $spacing-xs;
      }
    }
  }

  .noq-widget-builder-body {
    max-width: 694px;
    margin: 0 auto;
    min-height: calc(100vh - 304px);
    padding: $spacing-xxl;
    margin-bottom: $spacing-6xl;

    &__white-background {
      background-color: $color_white;
    }
  }
}
</style>

<style lang="scss">
.noq-widget-builder-header-helper {
  .home-header {
    padding: unset;
    background-color: unset;
  }
}
</style>
