<template>
  <div class="noq-radio-toolbar">
    <div
      v-for="(radio, i) in radioButtons"
      :key="i"
      class="noq-radio-button"
      :class="{
        'noq-radio-not-splitted': !isButtonsSplitted
      }"
      :style="{
        width: radioButtonWidth
      }"
    >
      <input
        type="radio"
        :id="'radio' + radio.value"
        :name="groupName"
        :value="radio.value"
        :checked="radio.selected"
        @click="$emit('value-changed', radio.value)"
      >
      <label
        :for="'radio' + radio.value"
        :class="{ 'noq-radio-button-big-label': size === 'big' }"
      >
        {{ radio.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupName: {
      type: String,
      required: true
    },
    radioButtons: {
      type: Array,
      required: true
    },
    isButtonsSplitted: {
      type: Boolean,
      required: false,
      default: true
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    }
  },
  computed: {
    radioButtonWidth() {
      if (this.isFullWidth) {
        return 100 / this.radioButtons.length + '%'
      }
      return 'unset'
    }
  }
}
</script>

<style lang="scss" scoped>
.noq-radio-toolbar {
  .noq-radio-button {
    display: inline-block;

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;

      // &:focus + label {
      //   border: 2px dashed #444;
      // }

      &:checked + label {
        background-color: $color_dark-gray;
      }
    }

    label {
      @include text_lg;
      width: 100%;
      display: inline-block;
      background-color: $color_white;
      min-width: 108px;
      text-align: center;
      border-radius: 4px;
      border: 1px solid $color_forms-border;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      &.noq-radio-button-big-label {
        padding: $spacing-m $spacing-s;
      }

      &:hover {
        background-color: $color_medium-gray;
      }
    }

    &.noq-radio-not-splitted {
      label {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
      }

      &:first-child {
        label {
          border-left: 1px solid $color_forms-border;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &:last-child {
        label {
          border-right: 1px solid $color_forms-border;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
</style>
