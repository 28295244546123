<template>
  <div class="pharma-map-page">
    <div class="map-list-wrapper">
      <div class="menu-wrapper">
        <div class="header-wrapper">
          <header-info />
        </div>
        <div class="location-search-wrapper" @click="hideHomePage">
          <map-search
            @search-item-selected="setSearchLocation"
            @location-query-cleared="setSearchLocation"
            @my-location-detected="setMyLocation"
          />
        </div>
        <div class="map-filter-wrapper" @click="hideHomePage">
          <map-filters />
        </div>
      </div>
      <div v-if="!isMapVisible" class="filters-layout"></div>
      <div
        v-if="dateTimeFilter"
        class="list-wrapper"
        :class="{ 'list-wrapper__mobile-visible': !isMapVisible }"
      >
        <locations-list
          :locations="visibleLocations"
          :recent-location="recentLocation"
          :date-time-filter="dateTimeFilter"
          @location-click="updateClickedLocation"
          @mouse-over-location="setHighlightedLocation"
        />
      </div>
      <div
        v-if="isHomePageVisible"
        class="home-page-info-wrapper"
      >
        <home-page-info :show-image="false" />
      </div>
      <div class="footer-wrapper">
        <footer-info />
      </div>
      <div class="map-wrapper" @click="hideHomePage">
        <google-map
          :locations="locations"
          :clicked-location="clickedLocation"
          :highlighted-location="highlightedLocation"
          :my-location="myLocation"
          :search-location="searchLocation"
          @visible-markers-changed="updateLocationsList"
          @date-time-changed="fetchLocations"
          @active-location-changed="updateActiveLocation"
        />
      </div>
      <div class="pin-info-window">
        <a
          v-if="activeLocation"
          href="#"
          @click="navToBooking(bookingLink(activeLocation, dateTimeFilter.date_range.start))"
        >
          <location-item
            :location-item="activeLocation"
            :recent-location="recentLocation"
            :date-time-filter="dateTimeFilter"
          />
        </a>
      </div>
    </div>
    <template v-if="visibleLocations.length">
      <div class="map-list-switch-controls" @click="isMapVisible = !isMapVisible">
        <div v-if="isMapVisible">
          <i class="noq-icon noq-list" />
          <div class="switch-controls-label">
            {{ $t('views.PharmaMap.showListing') }}
          </div>
        </div>
        <div v-else>
          <i class="noq-icon noq-map" />
          <div class="switch-controls-label">
            {{ $t('views.PharmaMap.showMap') }}
          </div>
        </div>
      </div>
    </template>
    <cookies-policy-info />
  </div>
</template>

<script>
import elasticQueryMixin from '@/mixins/elastic_query_fa.js'
import CookiesPolicyInfo from '@/components/CookiesPolicyInfo.vue'
import FooterInfo from '@/components/FooterInfo.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import HeaderInfo from '@/components/HeaderInfo.vue'
import HomePageInfo from '@/components/HomePageInfo.vue'
import LocationItem from '@/components/LocationItem.vue'
import LocationsList from '@/components/LocationsList.vue'
import MapFilters from '@/components/MapFilters.vue'
import MapSearch from '@/components/MapSearch.vue'
import urlsMixin from '@/mixins/url_mixin'

export default {
  name: 'PharmaMap',
  mixins: [elasticQueryMixin, urlsMixin],
  components: {
    CookiesPolicyInfo,
    FooterInfo,
    GoogleMap,
    HeaderInfo,
    HomePageInfo,
    LocationItem,
    LocationsList,
    MapFilters,
    MapSearch
  },
  data() {
    return {
      isMapVisible: true,
      locations: [],
      visibleLocations: [],
      activeLocation: null,
      highlightedLocation: null,
      recentLocation: null,
      clickedLocation: null,
      dateTimeFilter: null,
      myLocation: null,
      searchLocation: null,
      isHomePageVisible: true
    }
  },
  computed: {
    locationLogo() {
      return process.env.VUE_APP_LOCATOR_LOGO
    }
  },
  mounted() {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    this.detectMapCenter()
  },
  methods: {
    navToBooking(url) {
      window.open(url, '_blank');
      const event_id = process.env.VUE_APP_GTAG_NAV_TO_NOQ_EVENT_ID
      if (this.$gtag && event_id) {
        this.$gtag('event', 'conversion', {
          'send_to': event_id,
        });
      }
    },
    fetchLocations(dateTimeFilter) {
      this.dateTimeFilter = dateTimeFilter
      this.getLocationsByDateTime(dateTimeFilter)
      .then(data => this.locations = data)
    },
    updateActiveLocation(emittedLocation) {
      this.activeLocation = emittedLocation
      this.isMapVisible = true
    },
    updateLocationsList(emitedVisibleLocations) {
      this.visibleLocations = emitedVisibleLocations
    },
    setHighlightedLocation(location) {
      this.highlightedLocation = location
    },
    updateClickedLocation(clickedLocation) {
      this.clickedLocation = clickedLocation
    },
    setRecentLocation(location) {
      this.recentLocation = location
      this.isMapVisible = true
    },
    setMyLocation(myLocation) {
      this.myLocation = myLocation
      this.setRecentLocation(this.myLocation)
    },
    setSearchLocation(location) {
      this.searchLocation = location
      this.setRecentLocation(this.searchLocation)
    },
    hideHomePage() {
      this.isHomePageVisible = false
    },
    detectMapCenter() {
      if ('location' in this.$route.query) {
        const queryCoords = this.$route.query.location.split(',')
        this.myLocation = {
          lat: parseFloat(queryCoords[0]),
          lng: parseFloat(queryCoords[1])
        }
      }
    },
    detectZoomLevel() {
      if ('zoomLevel' in this.$route.query) {
        this.myLocationZoomLevel = parseInt(this.$route.query.zoomLevel)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pharma-map-page {
  @media (max-width: $breakpoint-tablet) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }

  .menu-wrapper {

    @media (min-width: $breakpoint-tablet) {
      position: relative;
      width: $desktop-left-bar-width;
      z-index: 9;
      background-color: $color_white;
      padding-bottom: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    }
  }

  .header-wrapper {
    position: relative;
    z-index: 20;
    width: $desktop-left-bar-width;
    background-color: $color_white;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .map-list-wrapper {
    position: relative;

    .map-filter-wrapper {
      margin-left: 12px;
      z-index: $z-index-map-filters;

      @media (max-width: $breakpoint-tablet) {
        top: 62px;
        position: fixed;
      }
    }

    .filters-layout {
      position: fixed;
      width: $desktop-left-bar-width;
      height: 124px;
      background-color: $color_white;
      top: $location-search-height;
      z-index: $z-index-locations-list-filter-layout;
      box-shadow: 0 6px 24px rgba(0, 0, 0, 0.05);

      @media (max-width: $breakpoint-tablet) {
        height: 52px;
        width: 100%;
      }
    }

    .list-wrapper {
      display: inline-block;
      background-color: $color_light-gray;
      width: $desktop-left-bar-width;

      @media (max-width: $breakpoint-tablet) {
        position: absolute;
        top: 101px;
        width: 100vw;
        z-index: $z-index-locations-list;
        padding-bottom: $location-search-height;
        display: none;
        padding-top: 0;

        &__mobile-visible {
          display: block;
        }
      }
    }

    .home-page-info-wrapper {
      position: absolute;
      top: 60px;
      left: 0;
      background-color: $color_white;
      display: inline-block;
      width: $desktop-left-bar-width;
      height: calc(100vh - 195px);
      overflow-y: scroll;
      margin-top: 133px;
      padding: 0 $spacing-m;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */

      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .footer-wrapper {
      z-index: 9;
      position: fixed;
      bottom: 0;
      width: 480px;
      background-color: #ffffff;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.07);

      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .map-wrapper {
      position: absolute;
      top: 0;
      left: $desktop-left-bar-width;
      width: calc(100% - #{$desktop-left-bar-width});
      vertical-align: top;

      @media (max-width: $breakpoint-tablet) {
        vertical-align: unset;
        position: unset;
        top: unset;
        left: unset;
        width: 100vw;
        height: calc(var(--app-height) - #{$location-search-height});
      }
    }

    .pin-info-window {
      display: none;

      @media (max-width: $breakpoint-tablet) {
        position: fixed;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: $color_white;
        z-index: $z-index-pin-info-window;
      }
    }
  }

  .map-list-switch-controls {
    display: none;

    @media (max-width: $breakpoint-tablet) {
      display: block;
      position: fixed;
      bottom: 0;
      @include text_lg;
      padding: $spacing-s $spacing-m;
      cursor: pointer;
      box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.05);
      background-color: $color_white;
      width: 100%;
      z-index: $z-index-map-list-switch;
    }

    .noq-icon {
      margin-right: $spacing-s;
    }

    .switch-controls-label {
      display: inline-block;
    }
  }
}
</style>
