<template>
  <div v-click-outside="hideResults" class="location-search">
    <i v-if="!isResultsVisible" class="noq-icon noq-search" />
    <i
      v-else
      class="noq-icon noq-arrow-left"
      @click="isResultsVisible = false"
    />
    <input
      ref='locationAutocompleteSearch'
      :value="locationQuery"
      class="location-search-input"
      type="text"
      :placeholder="$t('components.GoogleMap.locationQueryPlaceholder')"
      @click="unblockAutocompleteSearch"
      @input="locationQuery = $event.target.value"
    />
    <i
      v-if="locationQuery.length"
      class="noq-icon noq-x"
      :class="{
        'noq-x__my-location-hidden': !showCurrentLocationControl
      }"
      @click="clearLocationQuery"
    />
    <div
      v-if="showCurrentLocationControl"
      class="search-current-location-wrapper"
      :class="{
        'search-current-location-wrapper__no-bottom-border-radius': isResultsVisible
      }"
      @click="findMyLocation()"
    >
      <i class="noq-icon noq-gps" />
    </div>
    <div v-if="autocompletePlaces.length > 0 && isResultsVisible" class="pac-container pac-logo hdpi">
      <div
        v-for="(place, i) in autocompletePlaces"
        :key="i"
        class="pac-item"
        @click="getPlaceGeometry(place)"
      >
        <span class="pac-icon pac-icon-marker">
          <i class="noq-icon noq-pin" />
        </span>
        <span class="pac-item-query" v-html="highlightMatchedText(place)">
        </span>
        <span>{{ place.structured_formatting.secondary_text }}</span>
      </div>
    </div>
    <div v-else-if="showRecentResults" class="location-search-results">
      <recent-result-item
        v-for="(recentItem, i) in getRecentSearches()"
        :key="i"
        :recent-item="recentItem"
        @recent-search-selected="updateLocationQuery"
      />
    </div>
    <div ref='googlePlacesServiceHelper' class="google-places-helper"></div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import RecentResultItem from '@/components/RecentResultItem.vue'

const RECENT_SEARCHES = 'recent-searches'

export default {
  components: {
    RecentResultItem
  },
  props: {
    showCurrentLocationControl: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      locationQuery: '',
      isResultsVisible: false,
      googleAutocompleteService: null,
      googlePlacesService: null,
      autocompletePlaces: [],
      isAutocompleteSearchBlocked: false,
      sessionToken: null
    }
  },
  computed: {
    showRecentResults() {
      const recentSearches = this.getRecentSearches()
      return !this.locationQuery && recentSearches.length && this.isResultsVisible
    }
  },
  mounted() {
    // this.initGeocoder()

    this.googleAutocompleteService = new window.google.maps.places.AutocompleteService()
    this.googlePlacesService = new window.google.maps.places.PlacesService(this.$refs.googlePlacesServiceHelper)
    this.refreshSessionToken()
  },
  watch: {
    locationQuery: debounce(function(newVal) {
      if (newVal && newVal.length > 1 && !this.isAutocompleteSearchBlocked) {
        this.makePlacesRequest()
      } else {
        this.autocompletePlaces = []
      }
    }, 500),
  },
  methods: {
    refreshSessionToken() {
      this.sessionToken = new window.google.maps.places.AutocompleteSessionToken()
    },
    makePlacesRequest() {
      const restrictedCountries = process.env.VUE_APP_RESTRICTED_COUNTRIES.split(',')
      var request = {
        input: this.locationQuery,
        componentRestrictions: { country: restrictedCountries },
        fields: ["formatted_address", "name"],
        types: [],
        sessionToken: this.sessionToken
      }
      this.googleAutocompleteService.getPlacePredictions(request, res => {
        if (res.length > 0) {
          this.autocompletePlaces = res
          this.isResultsVisible = true
        } else {
          this.autocompletePlaces = []
        }
      })
    },
    // initGeocoder() {
    //   const restrictedCountries = process.env.VUE_APP_RESTRICTED_COUNTRIES.split(',')
    //   const input = this.$refs.locationAutocompleteSearch
    //   const options = {
    //     componentRestrictions: { country: restrictedCountries },
    //     fields: ["formatted_address", "geometry", "name"],
    //     types: [],
    //   };

    //   const autocomplete = new window.google.maps.places.Autocomplete(input, options)

    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace()

    //     if (!place.geometry || !place.geometry.location) {
    //       // User entered the name of a Place that was not suggested and
    //       // pressed the Enter key, or the Place Details request failed.
    //       console.log("No details available for input: '" + place.name + "'")
    //       this.locationQuery = place.name
    //       return
    //     }

    //     this.locationQuery = `${place.name}, ${place.formatted_address}`
    //     this.updateRecentGlobalSearch(place)
    //   })
    // },
    clearLocationQuery() {
      this.locationQuery = ''
      this.findedLocations = []
      this.$emit('location-query-cleared')
    },
    updateRecentGlobalSearch(place) {
      this.isAutocompleteSearchBlocked = true
      this.locationQuery = `${place.structured_formatting.main_text}, ${place.structured_formatting.secondary_text}`
      const placeName = place.structured_formatting.main_text

      let recentSearchQueries = this.getRecentSearches()

      recentSearchQueries = recentSearchQueries.filter(q => q !== placeName)
      recentSearchQueries.unshift(placeName)

      recentSearchQueries = recentSearchQueries.slice(0, 5)
      this.$cookies.set(RECENT_SEARCHES, JSON.stringify(recentSearchQueries))

      this.isResultsVisible = false
      this.$emit('search-item-selected', place)
    },
    getPlaceGeometry(place) {
      var request = {
        placeId: place.place_id,
        fields: ['geometry'],
        sessionToken: this.sessionToken
      }
      this.googlePlacesService.getDetails(request, (p, status) => {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
          place.geometry = p.geometry
          this.updateRecentGlobalSearch(place)
        }
      })
      this.refreshSessionToken()
    },
    getRecentSearches() {
      if (this.$cookies.get(RECENT_SEARCHES)) {
        return JSON.parse(this.$cookies.get(RECENT_SEARCHES))
      }
      return []
    },
    updateLocationQuery(recentSearch) {
      this.locationQuery = recentSearch
      this.$nextTick(() => {
        this.$refs.locationAutocompleteSearch.focus()
      })
    },
    hideResults() {
      this.isResultsVisible = false
    },
    findMyLocation() {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }

            this.$emit('my-location-detected', pos)

            return true
          },
          () => {
            this.handleLocationError(true)
            return false
          }
        )
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError(false)
        return false
      }
    },
    handleLocationError(browserHasGeolocation) {
      if (browserHasGeolocation) {
        console.log('Error: The Geolocation service failed.')
      } else {
        console.log('Error: Your browser doesn\'t support geolocation.')
      }
    },
    highlightMatchedText(place) {
      let retVal = place.structured_formatting.main_text
      if (place.structured_formatting.main_text_matched_substrings.length > 0) {
        const ms = place.structured_formatting.main_text_matched_substrings[0]
        const matched = retVal.substr(ms.offset, ms.length)
        retVal = retVal.replace(matched, `<span class="pac-matched">${matched}</span>`)
      }
      return retVal
    },
    unblockAutocompleteSearch() {
      this.isResultsVisible = true
      this.isAutocompleteSearchBlocked = false
    }
  }
}
</script>

<style lang="scss" scoped>
.location-search {
  position: relative;
  left: 0;
  z-index: $z-index-location-search;
  width: calc(#{$desktop-left-bar-width} - 2 * #{$spacing-s});
  margin: $spacing-s;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    margin: 0;
    top: 0;
  }

  .location-search-input {
    @include text_lg;
    height: $location-search-height;
    border: 0;
    padding: 0 96px 0 44px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
    width: 100%;
    color: $color_black;
    outline-width: 0;
    outline: none;
    border: 1px solid $color_forms-border;
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      border-radius: 4px 4px 0 0;
    }

    @media (max-width: $breakpoint-tablet) {
      border-bottom: 1px solid rgba(222, 226, 230, .4);
      border: 0;
      border-radius: unset;
    }

    @mixin textarea_placeholder_default {
      @include text_lg;
      color: $color_black;
      opacity: 0.6;
    }

    @mixin textarea_placeholder_focus {
      opacity: 0.4;
    }

    &::placeholder {
        @include textarea_placeholder_default;
    }

    &:focus::placeholder {
        @include textarea_placeholder_focus;
    }

    &::-webkit-input-placeholder {
        @include textarea_placeholder_default;
    }

    &:focus::-webkit-input-placeholder {
        @include textarea_placeholder_focus;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @include textarea_placeholder_default;
        line-height: 49px;
    }

    &:focus:-moz-placeholder {
        @include textarea_placeholder_focus;
        line-height: 49px;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @include textarea_placeholder_default;
        line-height: 49px;
    }

    &:focus::-moz-placeholder {
        @include textarea_placeholder_focus;
        line-height: 49px;
    }

    &:-ms-input-placeholder {
        @include textarea_placeholder_default;
    }

    &:focus:-ms-input-placeholder {
        @include textarea_placeholder_focus;
    }
  }

  .noq-search,
  .noq-arrow-left {
    position: absolute;
    top: $spacing-s;
    left: $spacing-s;
  }

  .noq-arrow-left {
    cursor: pointer;
  }

  .noq-x {
    position: absolute;
    top: $spacing-s;
    right: $spacing-6xl;

    &__my-location-hidden {
      right: $spacing-s;
    }
  }

  .search-current-location-wrapper {
    position: absolute;
    background-color: $color_dark-gray;
    top: 1px;
    right: 1px;
    height: calc(#{$location-search-height} - 2px);
    width: 56px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 0 4px 4px 0;

    @media (max-width: $breakpoint-tablet) {
      border: 0;
    }

    &__no-bottom-border-radius {
      border-bottom-right-radius: 0;
    }

    .noq-gps {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  .location-search-results {
    position: absolute;
    top: $location-search-height;
    background-color: $color_light-gray;
    z-index: $z-index-search-results;
    width: 100%;
    height: calc(100vh - #{$location-search-height});
    color: $color_gray;
    padding-top: $spacing-s;
    border: 1px solid $color_forms-border;
    border-top: 0;
    box-sizing: border-box;
    height: 212px;

    @media (max-width: $breakpoint-tablet) {
      height: calc(var(--app-height) - #{$location-search-height});
      border: unset;
      box-sizing: border-box;
    }
  }

  .google-places-helper {
    display: none;
  }
}
</style>

<style lang="scss">
// --------------------------
// Google Places Autocomplete
// --------------------------
.pac-container {
  position: absolute;
  top: $location-search-height;
  background-color: $color_light-gray;
  z-index: $z-index-search-results;
  width: 100%;
  min-height: 30px;
  max-height: 300px;
  color: $color_black;
  padding-top: $spacing-s;
  overflow-y: scroll;
  z-index: $z-index-autocomplete-results;
  box-shadow: unset;
  border: 1px solid #DEE2E6;
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;

  @media (max-width: $breakpoint-tablet) {
    height: calc(var(--app-height) - #{$location-search-height});
    min-height: unset;
    max-height: unset;
    box-sizing: border-box;
  }

  .pac-item {
    @include text_sm;
    padding: $spacing-xs;
    position: relative;
    border-top: 0;

    &:hover {
      background-color: $color_dark-gray;
      cursor: pointer;
    }

    & > span:nth-child(2) {
      margin-left: $spacing-xxl;
    }

    & > span:nth-child(3) {
      display: block;
      margin-left: $spacing-xxl;
    }

    .pac-icon {
      position: absolute;
      top: 16px;
      $radius: 6px;
      vertical-align: middle;
      display: inline-block;
      -moz-border-radius: $radius;
      -webkit-border-radius: $radius;
      border-radius: $radius;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: $spacing-xs;
      background-position: unset;
      margin-top: 0;
      background: unset;
    }

    .pac-item-query {
      @include text_lg;
      margin-left: $spacing-xxl;
      overflow:hidden; 
      white-space:nowrap; 
      text-overflow: ellipsis;

      .pac-matched {
        color: $color_gray;
        font-weight: normal;
      }
    }
  }
}
.hdpi.pac-logo:after {
  height: 0;
  background-image: unset;
}
</style>
