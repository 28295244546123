<template>
  <div class="widget-embed-code-component-wrapper">
    <h4>{{ $t('components.WidgetEmbedCode.embedCode') }}</h4>
    <div class="m-b-m">
      {{ $t('components.WidgetEmbedCode.embedCodeDescr') }}
    </div>
    <div class="noq-widget-code-generated">
      {{ embedCode }}
    </div>
    <div id="embedCode" class="noq-button noq-button__primary" @click="copyTextToClipboard()">
      {{ $t('components.WidgetEmbedCode.copyCode') }}
    </div>
    <span v-if="isCodeCopied" class="noq-widget-embed-code-copied-notification">
      {{ $t('components.WidgetEmbedCode.copiedToClipboard') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    embedCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isCodeCopied: false
    }
  },
  methods: {
    // https://stackoverflow.com/a/30810322
    copyTextToClipboard() {
      var textArea = document.createElement("textarea");

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';

      textArea.value = this.embedCode;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
        if (successful) {
          this.isCodeCopied = true
        }
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);
    }
  }
}
</script>

<style lang="scss">
.widget-embed-code-component-wrapper {
  .noq-widget-code-generated {
    margin-bottom: $spacing-xxl;
    padding: $spacing-s;
    background: $color_white;
    border: 1px solid $color_forms-border;
    box-sizing: border-box;
    word-break: break-all;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }

  .noq-widget-embed-code-copied-notification {
    padding-left: $spacing-s;
  }
}
</style>
