var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location-item-wrapper",class:{ 'location-item-wrapper__is-active': _vm.locationItem.isActive }},[_c('div',{staticClass:"location-item"},[_c('div',{staticClass:"location-item-logo",style:({
        'background-image': `url(${_vm.getLogo}), url(${_vm.getDefaultLogo})`
      })}),_c('div',{staticClass:"location-item-name-wrapper",class:{
            'location-item-name-wrapper__full-width': !_vm.locationItem.availability
          }},[_c('div',{staticClass:"location-item-name"},[_vm._v(" "+_vm._s(_vm.locationItem.name)+" ")]),(_vm.availabilityBadgeText(_vm.locationItem))?_c('div',{staticStyle:{"padding":"6px 0px"}},[_c('span',{staticClass:"location-item-availability-badge",style:({color: _vm.locationItem.free === 1 ? 'red' : ''})},[_vm._v(" "+_vm._s(_vm.availabilityBadgeText(_vm.locationItem))+" ")])]):_vm._e(),_c('div',{staticClass:"location-item-address"},[_c('span',{staticClass:"location-item-address-name",class:{
            'location-item-address-name__fixed-width': _vm.isWidget
          }},[_vm._v(" "+_vm._s(_vm.locationItem.address)+", "+_vm._s(_vm.locationItem.city)+" ")]),(_vm.recentLocationDistance)?_c('span',{staticClass:"location-item-address-distance"},[_vm._v(" • "+_vm._s(_vm.recentLocationDistance)+" ")]):_vm._e()]),(_vm.allIntegrations.length > 0)?_c('div',{staticClass:"location-item-integrations"},[(_vm.locationItem.appIntegrations.includes('cwa'))?_c('div',{staticClass:"app-integration-icon",style:({
            'background-image': `url(${require ('@/assets/icons/cwa_app_icon.png')}`
          })}):_vm._e(),(_vm.locationItem.appIntegrations.includes('dcc'))?_c('div',{staticClass:"app-integration-icon",style:({
            'background-image': `url(${require ('@/assets/icons/dcc_icon.png')}`
          })}):_vm._e(),(_vm.locationItem.appIntegrations.includes('luca'))?_c('div',{staticClass:"app-integration-icon",style:({
            'background-image': `url(${require ('@/assets/icons/luca_app_icon.png')}`
          })}):_vm._e()]):_vm._e()]),(!_vm.isWidget && _vm.locationItem.availability)?_c('div',{staticClass:"location-item-btn-wrapper"},[_c('a',{staticClass:"noq-button noq-button__primary noq-button__sm",class:{ 'noq-button__disabled': _vm.locationItem.first_available === null },attrs:{"href":"#"},on:{"click":[function($event){_vm.navToBooking(_vm.bookingLink(_vm.locationItem, _vm.dateTimeFilter.date_range.start))},function($event){$event.stopPropagation();}]}},[(_vm.locationItem.booketOut)?[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.booketOut'))+" ")]:(_vm.locationItem.free > 0 || _vm.locationItem.first_available != null)?[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.bookNow'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('components.LocationItem.notAvailable'))+" ")]],2)]):(_vm.locationItem.availability)?_c('div',{staticClass:"location-item-values-wrapper"},[_c('div',{staticClass:"location-item-values-free"},[_vm._v(" "+_vm._s(_vm.locationItem.free)+" ")]),_c('div',{staticClass:"location-item-values-label"},[_vm._v(" free ")])]):_c('div',{staticClass:"contacts-toggle-btn-wrapper"},[_c('a',{staticClass:"contacts-toggle-btn",attrs:{"href":"#"},on:{"click":[_vm.toggleContactsBar,function($event){$event.stopPropagation();}]}},[_c('i',{staticClass:"noq-icon",class:_vm.show_contacts_bar ? 'noq-arrow-down' : 'noq-arrow-right'})])])]),(!_vm.locationItem.availability && _vm.show_contacts_bar)?_c('contacts-bar',{attrs:{"location-item":_vm.locationItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }