<template>
  <div class="widget-page">
    <widget />
  </div>
</template>

<script>
import Widget from '@/components/Widget.vue'

export default {
  name: 'LocatorWidget',
  components: {
    Widget
  }
}
</script>
