<template>
  <div class="location-items-container">
    <div class="location-items-wrapper">
      <div
        v-for="(location, i) in locationItems"
        :key="`${i}-desktop`"
        :class="{ 'desktop-visible': !isWidget }"
      >
        <location-item
          :location-item="location"
          :recent-location="recentLocation"
          :date-time-filter="dateTimeFilter"
          :is-widget="isWidget"
          @mouseover.native="$emit('mouse-over-location', location)"
          @mouseleave.native="$emit('mouse-over-location', null)"
        />
      </div>
      <template v-if="!isWidget">
        <div
          class="tablet-visible"
          v-for="(location, i) in locationItems"
          :key="`${i}-tablet`"
        >
          <location-item
            :location-item="location"
            :recent-location="recentLocation"
            :date-time-filter="dateTimeFilter"
            :is-widget="isWidget"
            @mouseover.native="$emit('mouse-over-location', location)"
            @mouseleave.native="$emit('mouse-over-location', null)"
          />
        </div>
      </template>
      <div v-if="isWidget" class="widget-footer">
        <a :href="moreLocationsLink" target="_blank">
          More locations
        </a>
      </div>
      <add-item v-else-if="showFormLink" />
    </div>
  </div>
</template>

<script>
import urlsMixin from '@/mixins/url_mixin'
import LocationItem from '@/components/LocationItem.vue'
import AddItem from '@/components/AddItem.vue'

import { DateTime } from 'luxon'

const MAX_VISIBLE_LOCATIONS = 50
const MAXIMUM_WIDGET_LOCATION_DISTANCE = 20 // 20km

export default {
  components: {
    LocationItem,
    AddItem
  },
  mixins: [urlsMixin],
  props: {
    dateTimeFilter: {
      type: Object,
      required: true
    },
    locations: {
      type: Array,
      required: true
    },
    recentLocation: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    maxLocations: {
      type: Number,
      required: false,
      default: MAX_VISIBLE_LOCATIONS
    },
    isWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    verticalWidgetSort: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    // move activeLocation to the beginning of the list
    locationItems() {
      var locationItems = this.locations
      if (this.verticalWidgetSort) {
        this.sortLocationsByDistance(locationItems)
        let closeLocations = this.filterLocationsByDistance(locationItems)
        if (closeLocations.length) {
          locationItems = closeLocations.slice(0, this.maxLocations)
          this.sortLocationsByFreeSlots(locationItems)
        } else {
          locationItems = locationItems.slice(0, 3)
        }
      } else if (!this.recentLocation) {
        this.sortLocationsByFreeSlots(locationItems)
      } else {
        this.sortLocationsByDistance(locationItems)
      }
      locationItems.sort((x,y) => { return x.isActive === true ? -1 : y.isActive === true ? 1 : 0; })
      locationItems.sort((x,y) => { return (x.availability === y.availability ? 0 : x.availability ? -1 : 0)})
      locationItems = locationItems.slice(0, this.maxLocations)
      return locationItems
    },
    moreLocationsLink() {
      const hostName = location.protocol + "//" + location.host
      const lat = this.recentLocation.lat
      const lng = this.recentLocation.lng
      const coords = `${lat},${lng}`
      return `${hostName}/#/?location=${coords}`
    },
    showFormLink() {
      return process.env.VUE_APP_SHOW_FORM_LINK === "true" && this.locations.length <= 10
    },
  },
  methods: {
    // https://stackoverflow.com/a/27943
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      d = Number((d).toFixed(1))
      return d
    },
    deg2rad(deg) {
      return deg * (Math.PI/180)
    },
    emitClickedLocation(locationItem) {
      this.$emit('location-click', locationItem)
    },
    sortLocationsByDistance(locationItems) {
      let lat2
      let lng2
      if ('lat' in this.recentLocation && 'lng' in this.recentLocation) {
        lat2 = this.recentLocation.lat
        lng2 = this.recentLocation.lng
      } else {
        lat2 = this.recentLocation.geometry.location.lat()
        lng2 = this.recentLocation.geometry.location.lng()
      }
      // sort locations by distance from founded address
      locationItems.forEach(l => {
        l.distance = this.getDistanceFromLatLonInKm(
          l.lat,
          l.lng,
          lat2,
          lng2
        )
      })
      locationItems.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance))
    },
    sortLocationsByFreeSlots(locationItems) {
      locationItems.sort((a, b) => {
        const freeTodayComparison = parseFloat(b.free) - parseFloat(a.free)
        if (freeTodayComparison !== 0) return freeTodayComparison

        const aDate = a.first_available ? DateTime.fromISO(a.first_available) : null;
        const bDate = b.first_available ? DateTime.fromISO(b.first_available) : null;

        if (aDate && bDate) {
          return aDate - bDate; // Sort by date in ascending order
        } else if (aDate) {
          return -1; // aDate is defined, so it comes before bDate
        } else if (bDate) {
          return 1; // bDate is defined, so it comes before aDate
        }
        
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase()
      )
      })
    },
    filterLocationsByDistance(locationItems) {
      return locationItems.filter(l => {
        if (l.distance < MAXIMUM_WIDGET_LOCATION_DISTANCE) {
          return true
        }
      })
    }
  }
}
</script>

<style lang="scss">

.location-items-container {
  background-color: $color_light-gray;

  .location-items-topbar {
    @include text_sm;
    padding: $spacing-m;
    display: block;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .location-items-wrapper {
    height: calc(100vh - 225px);
    overflow-y: scroll;

    @media (max-width: $breakpoint-tablet) {
      height: calc(var(--app-height) - 151px);
      max-height: unset;
      margin-top: unset;
    }

    a {
      text-decoration: none;
    }

    .widget-footer {
      @include text_sm;
      text-align: center;
      padding: $spacing-s 0;

      a {
        text-decoration: none;
        color: $color_gray;
      }
    }
  }

  .form-link {
    padding: $spacing-m $spacing-s $spacing-m $spacing-xs;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color_white;
    margin-bottom: 2px;

    &:last-child {
      border-bottom: 0;
    }

    &:hover,
    &__is-active {
      background-color: $color_dark-gray;
      cursor: pointer;
    }

    @media (max-width: $breakpoint-tablet) {
      background-color: unset;
    }
  }
}
</style>
