import { DateTime } from "luxon";

export default {
  data(){
    return {
      bookingUrl: process.env.VUE_APP_BOOKING_BASE_URL
    }
  },
  methods: {
    bookingLink(locationItem, date) {
      console.log(locationItem)
      console.log(date)
      if (locationItem.website && locationItem.availability === false) {
        return locationItem.website
      } else {
        const dateString = new DateTime.fromObject(date)
        console.log(dateString)
        return `${this.bookingUrl}${locationItem.slug}/checkins/#/0/${dateString.toISODate()}`
      }
    }
  }
}
